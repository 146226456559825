import "./firstAnimation.css";
import { motion } from "framer-motion";
import { IsMobileContext } from '../App';
import * as React from "react";


export default function FirstAnimation() {
    const mobileContext = React.useContext(IsMobileContext);
    const windowHeight = window.innerHeight;
    return (
        <div style={{display: "flex", justifyContent: "center"}}>
            <motion.div  
      style={{ position: "absolute", display: "flex", top:"0px", left: "0px", zIndex: "4" }}
      animate={{opacity: [1, 0, 0], y: [0, 0, windowHeight*10*(-1)]}}
      transition={{times: [0, 0.99, 1], duration: 2.5, delay: 0.5}}
      >
      <div className="my-hidden-element" style={{position: "fixed", backgroundColor:"#FFFBE7", width:"100vw", height:"100vh", zIndex: 4 }}></div>
      </motion.div>
      <motion.div  
      style={{ position: "absolute", display: "flex",top:"0px", width: "50vw", justifySelf: "center", height: "100vh", alignItems: "center", justifyContent: "center", textAlign: "center", margin: "auto" ,zIndex: "5" }}
      className="nftMintHouse"
      animate={{top:"-42vh"}}
      transition={{duration: 2.5, delay: 0.5}}
      > 
      <img src="/images/nft-idol-house.png" className="nftMintHouseImage"/>
      </motion.div>
      <motion.div  
      className="nftMintPage"
      animate={{opacity: 1}}
      transition={{duration: 0.1, delay: 3}}
      > 
      {
        mobileContext?.isMobile ?
        <img src="/images/nft-mint-page.png" style={{width: "90px"}}/>
        :
        <img src="/images/nft-mint-page.png" className="nftMintPageImage"/>
      }
      </motion.div>
        </div>
    )
}