export const pathList = ["suzuna", "nae", "natsuki", "haruko"];

export const pathName = ["スズナページ", "ナエページ", "ナツキページ", "ハルコページ"]

export const salesLabelList = [
  "セール時間外",
  "1st",
  "2nd",
  "3rd",
  "4th",
  "Final",
];

