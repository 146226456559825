import { useEffect, useState } from "react";

import { pathList, salesLabelList, pathName } from "../const";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import "./displayInit.css";
import { abiThirdweb } from "../contract/abi_thirdweb";
import type { Address } from "wagmi";
import ReactLoading from "react-loading";

import { useContractReads } from "wagmi";
import { isMobile, browserName, isIOS } from "react-device-detect";

const IframeLink = (props: {
  src: string | undefined;
  href: string | undefined;
}) => {
  return (
    <div style={{ position: "relative" }}>
      <div style={{ pointerEvents: "none" }}>
        <iframe
          src={props.src}
          width="600px"
          height="600px"
          className="thirdweb-frame"
          title="thirdweb-frame"
        />
      </div>
      <a
        href={props.href}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          display: "inline-block",
          width: "100%",
          height: "100%",
          zIndex: 9999,
        }}
      ></a>
    </div>
  );
};

const ThirdwebFrame = ({ isShown }: { isShown: boolean }) => {
  const Note = () => {
    return (
      <div style={{ width: "260px", margin: "auto", fontSize: "12px" }}>
        下記枠内のどこかを一度タップし、 遷移先の
        <br />
        ページでウォレットを接続してください。
      </div>
    );
  };
  if (!isShown)
    return (
      <div>
        {isMobile && window.ethereum ? <Note /> : null}
        <div className="loading-frame">
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReactLoading color="#6f6dcf" type="balls" />
          </div>
        </div>
      </div>
    );
  if (isMobile && window.ethereum) {
    return (
      <div className="thid">
        <Note />
        <IframeLink
          src="https://embed.ipfscdn.io/ipfs/bafybeicd3qfzelz4su7ng6n523virdsgobrc5pcbarhwqv3dj3drh645pi/?contract=0x80278e26F64c030fDAD04De8DAB44A792eB3ad49&chain=%7B%22name%22%3A%22Ethereum+Mainnet%22%2C%22chain%22%3A%22ETH%22%2C%22rpc%22%3A%5B%22https%3A%2F%2Fethereum.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22Ether%22%2C%22symbol%22%3A%22ETH%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22eth%22%2C%22chainId%22%3A1%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22ethereum%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmcxZHpyJa8T4i63xqjPYrZ6tKrt55tZJpbXcjSDKuKaf9%2Fethereum%2F512.png%22%2C%22width%22%3A512%2C%22height%22%3A512%2C%22format%22%3A%22png%22%7D%7D&clientId=3e4a095ccfdc87766c87fe02317c1db8&theme=light&primaryColor=purple"
          href="https://embed.ipfscdn.io/ipfs/bafybeicd3qfzelz4su7ng6n523virdsgobrc5pcbarhwqv3dj3drh645pi/?contract=0x80278e26F64c030fDAD04De8DAB44A792eB3ad49&chain=%7B%22name%22%3A%22Ethereum+Mainnet%22%2C%22chain%22%3A%22ETH%22%2C%22rpc%22%3A%5B%22https%3A%2F%2Fethereum.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22Ether%22%2C%22symbol%22%3A%22ETH%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22eth%22%2C%22chainId%22%3A1%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22ethereum%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmcxZHpyJa8T4i63xqjPYrZ6tKrt55tZJpbXcjSDKuKaf9%2Fethereum%2F512.png%22%2C%22width%22%3A512%2C%22height%22%3A512%2C%22format%22%3A%22png%22%7D%7D&clientId=3e4a095ccfdc87766c87fe02317c1db8&theme=light&primaryColor=purple"
        />
      </div>
    );
  } else {
    return (
      <div>
        <iframe
          width="600px"
          height="600px"
          className="thirdweb-frame"
          title="thirdweb-frame"
          src="https://embed.ipfscdn.io/ipfs/bafybeicd3qfzelz4su7ng6n523virdsgobrc5pcbarhwqv3dj3drh645pi/?contract=0x80278e26F64c030fDAD04De8DAB44A792eB3ad49&chain=%7B%22name%22%3A%22Ethereum+Mainnet%22%2C%22chain%22%3A%22ETH%22%2C%22rpc%22%3A%5B%22https%3A%2F%2Fethereum.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22Ether%22%2C%22symbol%22%3A%22ETH%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22eth%22%2C%22chainId%22%3A1%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22ethereum%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmcxZHpyJa8T4i63xqjPYrZ6tKrt55tZJpbXcjSDKuKaf9%2Fethereum%2F512.png%22%2C%22width%22%3A512%2C%22height%22%3A512%2C%22format%22%3A%22png%22%7D%7D&clientId=3e4a095ccfdc87766c87fe02317c1db8&theme=light&primaryColor=purple"
        ></iframe>
      </div>
    );
  }
};

const LinkSelectionCard = ({ onClick }: { onClick: () => void }) => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const getParam = query.get("name");

  const exclusivePathList = pathList.filter((path) => path !== getParam);
  


  return (
    <div className="link-selection-card">
      {getParam ? (
        <div>
          <p className="link-selection-title">リトライ</p>
          <p className="link-selection-message">他の選択肢を試す</p>
        </div>
      ) : null}
      <ul className="link-selection-items">
        {exclusivePathList.map((path) => {
          return (
            <li key={path}>
              <Link
                to={{ pathname: "/", search: `?${"name"}=${path}` }}
                onClick={onClick}
              >
                {pathName[pathList.indexOf(path)]}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const DispalyInit = () => {
  const answerPath = pathList[Number(3)];
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const getParam = query.get("name");
  const navigate = useNavigate();

  const isCorrect = getParam === answerPath;
  const [isRevealed, setIsRevealed] = useState<boolean>(false);
  const [isMotionStop, setIsMotionStop] = useState<boolean>(false);

  const { data } = useContractReads({
    contracts: [
      {
        address: process.env.REACT_APP_DEVNET_CONTRACT_ADDRESS as Address,
        abi: abiThirdweb,
        functionName: "getActiveClaimConditionId",
      },
      {
        address: process.env.REACT_APP_DEVNET_CONTRACT_ADDRESS as Address,
        abi: abiThirdweb,
        functionName: "totalSupply",
      },
    ],
  });
  const totalSupply = Number(data?.[1]);
  const status = totalSupply === 10 ? 0 : Number(data?.[0]);
  const handleReveal = () => {
    setIsRevealed(true);
  };
  const handleHide = () => {
    setIsRevealed(false);
  };
  const salesLabel = salesLabelList[status];
  useEffect(() => {
    if (!isNaN(status) && status !== 1 && getParam) navigate("/");
    if (status === 1 && !getParam) setIsRevealed(true);
  }, [navigate, getParam, status]);

  useEffect(() => {
    setTimeout(() => {
      setIsMotionStop(true);
    }, 3.5 * 1000);
  }, []);

  if (status === 0)
    return (
      <div className="display-init">
        <p className="sale-status-text">Sale Status</p>
        <p className="title-text">{salesLabel}</p>
      </div>
    );

  if (status === 1)
    return (
      <div className="display-init">
        <p className="sale-status-text">Sale Status</p>
        <p className="title-text">{salesLabel}</p>
        {!isRevealed ? isMotionStop?(
          <button className="reveal-button" onClick={handleReveal}>
          確認
        </button>
        ) : (
          <button className="reveal-button" >
            読み込み中...
          </button>
        ) : isCorrect ? (
          <div>
            <p className="title-text">
              正解です！🎉
              <br />
              下記よりウォレットを接続し、
              <br />
              購入してください。（先着順）
            </p>
            <ThirdwebFrame isShown={isMotionStop} />
          </div>
        ) : (
          <div>
            <p className="title-text">
              {!getParam ? "選択肢を選ぶ" : "残念！不正解です😭"}
            </p>
            <LinkSelectionCard onClick={handleHide} />
          </div>
        )}
      </div>
    );

  return (
    <div className="display-init">
      <p className="sale-status-text">Sale Status</p>
      <p className="title-text">{salesLabel}</p>
      <ThirdwebFrame isShown={isMotionStop} />
    </div>
  );
};
