import "./topAnimation.css";
import { motion } from "framer-motion";
import { useState } from "react";
import { IsMobileContext } from '../App';
import * as React from "react";

// const 

export default function TopAnimation() {
  const mobileContext = React.useContext(IsMobileContext);
  const easeArray = [0.25, 0, 0.4, 1];

  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [browserHeight, setBrowserHeight] = useState(window.innerHeight);

  React.useEffect(() => {
    const handleResize = () => {
      setBrowserWidth(window.innerWidth);
    };

    handleResize(); 
  }, [])

  const frontImageWidth = browserWidth > 1200 ? browserWidth*0.14 : 210;

  const frontImageHeight = browserWidth > 1200 ? browserWidth*0.14 : 210;


  const frontWordWidth =  browserWidth > 1200 ? browserWidth*0.4 : 300;

  const  backImageWidth = browserWidth > 1200 ? browserWidth*0.09 : 120;

  const backImageHeight =  browserWidth > 1200 ? browserWidth*0.09 : 120;

  const backWordWidth = browserWidth > 1200 ? browserWidth*0.8 : 700;

  const animateX = browserWidth > 1200 ? browserWidth*2 : 1400;



  const fixedImageHeight = browserHeight < 600 ? 175 : frontImageHeight;

  const fixedfrontImageWidth = browserHeight < 600 ? 175 : frontImageWidth;

  const fixedfrontWordWidth = browserHeight < 600 ? 250 : frontWordWidth;

  const fixedbackImageWidth = browserHeight < 600 ? 100 : backImageWidth;


  const fixedbackImageHeight = backImageHeight < 600 ? 100 : backImageHeight;
  const fixedbackWordWidth = browserHeight < 600 ? 583 : backWordWidth;







  const frontInitImageNum: number = Math.ceil((browserWidth / fixedfrontImageWidth) + 60);
  const backInitImageNum: number= Math.ceil((browserWidth / fixedbackImageWidth) + 60);

  const frontImageParts = (left: number, imageUrl: string): JSX.Element => {
    return <div style={{ position: "absolute", display: "flex", left: left-120, height: "auto", overflow: "hidden", width: fixedfrontImageWidth, justifyContent: "end" }}>
       <img src={imageUrl} height={fixedImageHeight*0.86} style={{float: "right"}}/>
    </div>
  }
  const frontWordParts = (left: number): JSX.Element => {
    return <img src="/images/nft-idol-fuhua.png" width={fixedfrontWordWidth} className="" style={{ position: "absolute", display: "flex", left: left-120, height: "auto", overflow: "hidden" }}/>
  }
  const backImageParts = (left: number, imageUrl: string): JSX.Element => {

    return <div className="blur" style={{ position: "absolute", display: "flex", left: left-120, height: fixedbackImageHeight, width: fixedbackImageWidth, justifyContent: "end" }}>
    <img src={imageUrl} height={fixedbackImageWidth*0.86} style={{float: "right"}}/>
 </div>
  }

  const backWordParts = (left: number): JSX.Element => {

    return <img src="/images/nft-idol-fuhua.png" width={fixedbackWordWidth} className="" style={{ position: "absolute", display: "flex", left: left-120, height: "auto", overflow: "hidden" }}/>
  }

  const frontInitImageNumArray = [...Array(frontInitImageNum)].map((_, i) => i);
  const backInitImageNumArray =[...Array(backInitImageNum)].map((_, i) => i);
 
  const frontImages: JSX.Element[]  = frontInitImageNumArray.map((value) => {
    const imageUrl = `/images/top-front-icons-mins/${value%8 + 1}.png`
    return frontImageParts(-fixedfrontImageWidth+fixedfrontImageWidth*value, imageUrl)
  });

  const frontWordImages: JSX.Element[]  = frontInitImageNumArray.map((value) => {
    return frontWordParts(((-2.5)*animateX)+fixedfrontWordWidth*value)
  });

  const backImages: JSX.Element[] = backInitImageNumArray.map((value) => {
    const imageUrl = `/images/top-back-icons/${value%8 + 1}.png`
    return backImageParts(((-2.5)*animateX)+fixedbackImageWidth*value, imageUrl)
  });

  const backWordImages: JSX.Element[] = backInitImageNumArray.map((value) => {
    return backWordParts(-fixedfrontWordWidth+fixedbackWordWidth*value)
  });

  const front = frontImages;
  const back = backImages;
  const frontWord = frontWordImages;
  const backWord = backWordImages;

  return (
    <div className="topImage">
      <motion.div
        className="backImages"
        animate={{ x: animateX}}
        transition={{ duration: 20, ease: easeArray, delay: 3 }}
      >
        {back.map((value) => {
          return value;
        })}
      </motion.div>
      <motion.div
        className="backWords"
        animate={{x: -1*animateX}}
        transition={{duration: 20, ease: easeArray, delay: 3 }}
      >
        {backWord.map((value) => {
          return value;
        })}
      </motion.div>
      {mobileContext?.isMobile? <img src="/images/mobile.png" className="centerImage"  width='106%'/> :<img src="/images/top-images-center.png" className="centerImage"  height='600%'/>}
      <motion.div
        className="frontImages"
        animate={{ x: -1*animateX }}
        transition={{ duration: 20, ease: easeArray, delay: 3}}
      >
        {front.map((value) => {
          return value;
        })}
      </motion.div>
      <motion.div
        className="frontWords"
        animate={{ x: 1*animateX }}
        transition={{ duration: 20, ease: easeArray, delay: 3}}
      >
        {frontWord.map((value) => {
          return value;
        })}
      </motion.div>
    </div>
  );
}
