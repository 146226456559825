import "./specifiedCommercialTransaction.css";
import AppFooter from "./appFooter";

export default function SpecifiedCommercialTransactions() {
  return (
    <>
      <div className="specified-commercial-transaction">
        <div className="header"></div>
        <div className="nft-idol-house">
          <img src="/images/nft-idol-house.png" className="nftMintHouseImage" />
        </div>
        <div className="nft-mint-page">
          <img src="/images/nft-mint-page.png" className="nftMintPageImage" />
        </div>
        <div className="title">特定商取引法に基づく表記</div>
        <div className="content">
          <div className="content-left"></div>
          <div className="content-center">
            <SpecialNote />
          </div>
          <div className="content-right"></div>
        </div>
      </div>
      <div className="App-footer">
        <AppFooter />
      </div>
    </>
  );
}

const SpecialNote = () => {
  return (
    <>
      <p>{/* <span>特定商取引法に基づく表記</span> */}</p>
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>販売業者名</span>
        <span style={{ width: "31.5pt", display: "inline-block" }}>&#xa0;</span>
        <span style={{ width: "42pt", display: "inline-block" }}>&#xa0;</span>
        <span style={{ width: "42pt", display: "inline-block" }}>&#xa0;</span>
      </p>
      <p style={{ marginLeft: "42pt" }}>
        <span>日本テレビ放送網株式会社</span>
      </p>
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>販売業者住所</span>
        <span style={{ width: "21pt", display: "inline-block" }}>&#xa0;</span>
        <span style={{ width: "42pt", display: "inline-block" }}>&#xa0;</span>
      </p>
      <p style={{ marginLeft: "42pt" }}>
        <span>東京都港区東新橋一丁目6-1</span>
      </p>
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>販売責任者</span>
        <span style={{ width: "31.5pt", display: "inline-block" }}>&#xa0;</span>
      </p>
      <p style={{ marginLeft: "42pt" }}>
        <span>
          コンテンツ戦略本部コンテンツ戦略局 総合編成センター　原 浩生
        </span>
      </p>
      {/* --- */}
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>連絡先／電話番号</span>
        <span style={{ width: "31.5pt", display: "inline-block" }}>&#xa0;</span>
      </p>
      <p style={{ marginLeft: "42pt" }}>
        <span>03-6215-2856</span>
      </p>
      {/* --- */}
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>販売価格・販売数量</span>
        <span style={{ width: "31.5pt", display: "inline-block" }}>&#xa0;</span>
      </p>
      <p style={{ marginLeft: "42pt" }}>
        <span>
          販売ページに記載しています。なお、消費税は税込として表示しております。
        </span>
      </p>
      <p style={{ marginLeft: "42pt" }}>
        <span>送料は発生しません。 商品には販売数量の上限があります。</span>
      </p>
      <p style={{ marginLeft: "42pt" }}>
        <span>※ネットワーク手数料（ガス代）が別途かかります。</span>
      </p>
      {/* --- */}
      {/* --- */}
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>引き渡し時期</span>
        <span style={{ width: "31.5pt", display: "inline-block" }}>&#xa0;</span>
      </p>
      <p style={{ marginLeft: "42pt" }}>
        <span>決済完了後、速やかに提供します。</span>
      </p>
      {/* --- */}
      {/* --- */}
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>代金の支払方法</span>
        <span style={{ width: "31.5pt", display: "inline-block" }}>&#xa0;</span>
      </p>
      <p style={{ marginLeft: "42pt" }}>
        <span>暗号資産（ETH）によるお支払いとなります。</span>
      </p>
      {/* --- */}
      {/* --- */}
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>代金の支払時期</span>
        <span style={{ width: "31.5pt", display: "inline-block" }}>&#xa0;</span>
      </p>
      <p style={{ marginLeft: "42pt" }}>
        <span>購入手続き完了と同時に決済されます。</span>
      </p>
      {/* --- */}
      {/* --- */}
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>利用環境</span>
        <span style={{ width: "31.5pt", display: "inline-block" }}>&#xa0;</span>
      </p>
      <p style={{ marginLeft: "42pt" }}>
        <span>
          ご利用いただくには、利用規約に定める所定の利用環境の準備が必要となります。
        </span>
      </p>
      {/* --- */}
      {/* --- */}
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>返品、交換、キャンセル等について</span>
        <span style={{ width: "31.5pt", display: "inline-block" }}>&#xa0;</span>
      </p>
      <p style={{ marginLeft: "42pt" }}>
        <span>
          当社の責めに帰すべき場合を除き、購入手続完了後の申込みの撤回、解除はできず、返品、交換、キャンセルはお受けすることができません。
        </span>
      </p>
      {/* --- */}
    </>
  );
};
