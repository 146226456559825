import React, { createContext, useEffect } from "react";

import "./App.css";
import TopAnimation from "./components/topAnimation";
import Connection from "./components/connection";
import {
  WagmiConfig,
  createClient,
  configureChains,
  goerli,
  mainnet,
} from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import Explanation from "./components/explanation";
import AppFooter from "./components/appFooter";
import FirstAnimation from "./components/firstAnimation";
import { Toaster } from "react-hot-toast";
import { DispalyInit } from "./components/dispalyInit";

const chain = process.env.REACT_APP_CHAIN_ID === "5" ? goerli : mainnet;
const { provider, webSocketProvider } = configureChains(
  [chain],
  [publicProvider()]
);

const client = createClient({
  autoConnect: true,
  provider,
  webSocketProvider,
});

type LanguageState = {
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
};

type isMobileState = {
  isMobile: boolean;
  setIsMobile: React.Dispatch<React.SetStateAction<boolean>>;
};

export const LanguageContext = createContext<LanguageState | null>(null);

export const IsMobileContext = createContext<isMobileState | null>(null);

function App() {
  const [language, setLanguage] = React.useState("ja");
  const [isMobile, setIsMobile] = React.useState(false);

  useEffect(() => {
    const userAgent =
      typeof navigator === "undefined" ? "" : navigator.userAgent;
    const mobileRegex =
      /Mobile|Android|iOS|iPhone|iPad|iPod|Windows Phone|BlackBerry|Tablet|Kindle|Silk|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i;
    setIsMobile(!!userAgent.match(mobileRegex));
  }, []);

  const value: LanguageState = {
    language,
    setLanguage,
  };

  const isMobileValue: isMobileState = {
    isMobile,
    setIsMobile,
  };

  return (
    <LanguageContext.Provider value={value}>
      <IsMobileContext.Provider value={isMobileValue}>
        <WagmiConfig client={client}>
          <div>
            <div
              className="App-header"
              style={{ position: "relative", zIndex: 1 }}
            >
              <TopAnimation />
            </div>
            <div
              className="contents-wrapper"
              style={{ position: "relative", zIndex: 2 }}
            >
              <div className="contents">
                <div className="display-init">
                  <DispalyInit />
                </div>
                <div className="explanation-container">
                  <Explanation />
                </div>
              </div>
            </div>
            <div className="App-footer">
              <AppFooter />
            </div>
          </div>
          <div>
            <FirstAnimation />
          </div>
          <Toaster />
        </WagmiConfig>
      </IsMobileContext.Provider>
    </LanguageContext.Provider>
  );
}

export default App;
