import "./termsOfService.css";
import AppFooter from "./appFooter";

export default function TermsOfService() {
  return (
    <>
      <div className="terms-of-service">
        <div classNamse="header"></div>
        <div className="nft-idol-house">
          <img src="/images/nft-idol-house.png" className="nftMintHouseImage" />
        </div>
        <div className="nft-mint-page">
          <img src="/images/nft-mint-page.png" className="nftMintPageImage" />
        </div>
        <div className="title">NFT IDOL HOUSE　LAST 10 SALE SBT利用規約</div>
        <div className="content">
          <div className="content-left"></div>
          <div className="content-center">
            <p></p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span>
                この利用規約（以下「本規約」といいます。）は、日本テレビ放送網株式会社（以下「当社」といいます。）が、本サイト（第１条で定義）において販売するデジタルコンテンツに関する使用条件その他の事項を定めるものになります。
              </span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span>第1条　定義</span>
            </p>
            <p>
              <span>
                本規約における用語の意味は、別途定義される場合を除き、以下の各号に定めるとおりです。
              </span>
            </p>
            <p>
              <span>
                (1)　「本サイト」とは、日本テレビが運営するウェブサイト「NFT
                IDOL HOUSE mint page」をいいます。
              </span>
            </p>
            <p>
              <span>
                (2)　「ユーザー」とは、本サイトを利用する者をいいます。
              </span>
            </p>
            <p>
              <span>
                (3)　「購入ユーザー」とは、本サイト上で、本コンテンツを購入したユーザーをいいます。
              </span>
            </p>
            <p>
              <span>
                (4)　「本コンテンツ」とは、本サイト上で、当社が販売する、映像又は画像などのデジタルコンテンツをいいます。なお、本コンテンツはブロックチェーンに記録されたNon-Fungible
                Tokenの形式で発行されます。
              </span>
            </p>
            <p>
              <span>
                (5)　「２次流通」とは、本コンテンツが外部マーケットプレイスにて購入ユーザーから他者に転売されることをいい、その後の転売過程を含みます。
              </span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span>第2条　適用範囲</span>
            </p>
            <p>
              <span>
                本規約は、本コンテンツの購入申し込みをしたユーザーに対して適用されるものとします。
              </span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span>第3条　本規約への同意</span>
            </p>
            <p>
              <span>
                本コンテンツの購入申し込みをするには、ユーザーは、本規約に同意しなければなりません。
              </span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span>第4条 </span>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
              <span>ウォレットの利用等</span>
            </p>
            <p>
              <span>
                1.
                本サイトを利用して本コンテンツを購入するためには、ユーザーがMetaMaskその他の暗号資産管理ウォレットを自らの責任により用意する必要があります。
              </span>
            </p>
            <p>
              <span>
                2.
                ユーザーは、本サイトの利用にあたり、自身のウォレットの秘密鍵又はシードフレーズに関する情報その他本サイトの利用に伴い使用する一切の情報（以下「ウォレット情報等」といいます。）を自らの責任において管理し、第三者による不正使用を防止するために必要な措置を講じるものとし、自身のウォレット情報等を使用して自己又は第三者が行った行為について、一切の責任を負うものとします。
              </span>
            </p>
            <p>
              <span>
                3.
                当社は、ウォレット情報等を使用してなされた行為を、当該ウォレット情報等を管理すべき利用者による行為とみなすことができるものとします。
              </span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span>第５条 設備の負担等</span>
            </p>
            <p>
              <span>
                1.
                本コンテンツの提供を受けるために必要な、コンピューター、スマートフォン、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、ユーザーの責任において行うものとします。
              </span>
            </p>
            <p>
              <span>
                2.
                ユーザーは自己の本サイトの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。
              </span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span>第6条　本コンテンツの購入</span>
            </p>
            <p>
              <span>
                1.
                ユーザーは、本サイト所定の方法により、本サイト上で販売されている本コンテンツの購入申し込みをすることができるものとします。
              </span>
            </p>
            <p>
              <span>
                2.
                本ウェブサイト上に表示された本コンテンツの価格に相当するETHその他の暗号資産を当社が定めるアドレスに送付し、当社が当該暗号資産を現に受領した時点で、本コンテンツに関する売買契約（以下「本契約」といいます。）が成立するものとします。
              </span>
            </p>
            <p>
              <span>
                3.
                本コンテンツの購入に際して必要となるガス代（ブロックチェーンネットワークを利用する際に支払うETHその他の暗号資産建ての手数料をいいます。以下同じです。）は、ユーザーが負担するものとします。トランザクションエラーその他のブロックチェーンネットワークの不具合により、当社がETHその他の暗号資産を受領できなかった場合にユーザーが支出したガス代についても同様とします。
              </span>
            </p>
            <p>
              <span>
                4.
                当社は、購入ユーザーに対し、ブロックチェーン上に本コンテンツ及びその購入に係る一定の記録を行い、購入ユーザーのウォレットアドレスに本コンテンツを送付するものとし、これをもって当社による本契約の履行が完了するものとします。
              </span>
            </p>
            <p>
              <span>
                5.
                購入ユーザーは、第2項の本コンテンツの管理を自己の費用と責任で行うものとし、当社は本コンテンツの管理を行わず、一切の責任を負いません。また、当社は、本コンテンツに関して将来にわたり、利用できることを保証するものではありません。
              </span>
            </p>
            <p>
              <span>
                6.
                本コンテンツの代金決済が完了した後は、購入した本コンテンツの利用の有無を問わず、当社の責めに帰すべき場合を除き、返金、返品・交換は行わないものとします。
              </span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span>第7条 </span>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
              <span>料金及び費用</span>
            </p>
            <p>
              <span>
                1.
                本コンテンツの料金は、本ウェブサイト上における本コンテンツの購入ページに表示されます。
              </span>
            </p>
            <p>
              <span>
                2.
                本コンテンツの購入には、本コンテンツの料金の他に、ガス代が必要となります。
              </span>
            </p>
            <p>
              <span>
                3.
                本コンテンツの購入及び本サイトの利用に伴い、税金や附帯費用が発生する場合には、ユーザーがこれらを負担するものとします。
              </span>
            </p>
            <p>
              <span>
                4.
                本サイトの利用にあたって必要な端末の購入、導入又は維持に係る費用及び本サイトの利用の際に発生する一切の通信費用はユーザーがこれを負担するものとします。
              </span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span>第8条　本コンテンツの利用</span>
            </p>
            <p>
              <span>1. </span>
              <a name="_Hlk96211254">
                <span>
                  購入ユーザーは、次条で定める範囲内に限り、本コンテンツを利用することができます。
                </span>
              </a>
            </p>
            <p>
              <span>
                2.
                購入ユーザーは、外部マーケットプレイスを通じて、本コンテンツを２次流通させることはできません。
              </span>
            </p>
            <p className="ListParagraph" style={{ marginLeft: "21pt" }}>
              <span style={{ bookmarkEnd: "_Hlk96211254" }}></span>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span>第9条　商品利用に関する許諾内容</span>
            </p>
            <p>
              <a name="_Hlk96211306">
                <span>
                  1.
                  購入ユーザーは、次の利用範囲内に限り、本コンテンツを利用することができるものとします。
                </span>
              </a>
            </p>
            <p style={{ marginLeft: "21.2pt" }}>
              <span>
                ・本サイト所定の方法により、本コンテンツを閲覧すること
              </span>
            </p>
            <p style={{ marginLeft: "21.2pt" }}>
              <span>・本コンテンツを、自らのSNSのアイコン等に使用すること</span>
            </p>
            <p>
              <span>
                2.
                購入ユーザーは、本コンテンツの全部又は一部について、当社が別途認めた場合を除き、次の各号に定める行為を行うことはできません。
              </span>
            </p>
            <p className="ListParagraph" style={{ marginLeft: "21pt" }}>
              <span>
                (1) 私的使用以外の目的でプリントアウト、コピーその他複製すること
              </span>
            </p>
            <p className="ListParagraph" style={{ marginLeft: "21pt" }}>
              <span>(2) 本コンテンツをそのまま用いて別のNFTを発行すること</span>
            </p>
            <p className="ListParagraph" style={{ marginLeft: "21pt" }}>
              <span>(3) 第三者に配布すること</span>
            </p>
            <p className="ListParagraph" style={{ marginLeft: "21pt" }}>
              <span>
                (4)
                商業利用（展示し、入場料等を得ることを含む。）、書籍等へ掲載すること
              </span>
            </p>
            <p className="ListParagraph" style={{ marginLeft: "21pt" }}>
              <span>
                (5) 加工・改変し、又は著作権表示を削除・変更等すること
              </span>
            </p>
            <p className="ListParagraph" style={{ marginLeft: "21pt" }}>
              <span>(6) 別の作品内で２次利用すること</span>
            </p>
            <p style={{ marginLeft: "21pt" }}>
              <span>
                (7)
                複製物を販売し、グッズ化し、又は他の商品の宣伝広告目的や政治・宗教活動等で利用すること
              </span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0; </span>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>第10条　禁止事項</span>
            </p>
            <p>
              <span>
                購入ユーザーは、次の各号に定める行為又はそのおそれがある行為を行ってはならないものとます。
              </span>
            </p>
            <p>
              <span>
                (1)　当社その他第三者の財産権、著作権・商標権等の知的財産権、肖像権、パブリシティ権、名誉・プライバシー等を侵害すること
              </span>
            </p>
            <p>
              <span>
                (2)　性的・暴力的な表現、誹謗中傷、閲覧した者に過度の不快感を抱かせるおそれのある表現、差別的な表現を行うこと
              </span>
            </p>
            <p>
              <span>
                (3)　本規約で認められる利用範囲を超えて、本コンテンツを複製、販売、出版、貸与、放送、公衆送信（送信可能化を含みます。）、上映、改変、翻案等すること
              </span>
            </p>
            <p>
              <span>
                (4)　本コンテンツを保護するために施された技術的措置を回避
                ・無効化すること
              </span>
            </p>
            <p>
              <span>
                (5)　第三者に購入ユーザーのユーザー登録情報を譲渡、貸与すること
              </span>
            </p>
            <p>
              <span>
                (6)　ドメイン名やメールアドレスを不特定多数で共有すること
              </span>
            </p>
            <p>
              <span>
                (7)　本コンテンツの売買契約上の債権を第三者に譲渡するもしくは担保に供すること
              </span>
            </p>
            <p>
              <span>(8)　他者を不快にさせる行為</span>
            </p>
            <p>
              <span>(9)　法令や公序良俗に反する行為</span>
            </p>
            <p>
              <span>(10)　前各号に準じる不適切な行為</span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span>第11条　権利の帰属等</span>
            </p>
            <p>
              <span>
                1.
                本コンテンツの一切の所有権及び知的財産権（ここでいう知的財産権とは、著作権（著作権法第27条及び第28条に定める権利を含む。）、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含む｡）その他のあらゆる権利（開発途中、作成途中のものを含む。）の扱いは、当社に帰属するものとします。
              </span>
            </p>
            <p>
              <span>
                2.
                本コンテンツの売買によって、本コンテンツの所有権、著作権、肖像権、プライバシー権、パブリシティ権、商標権その他の権利が、当社から購入ユーザーに移転するものではありません。
              </span>
            </p>
            <p>
              <span>
                3.
                当社は、当社が定めるガイドラインに従い、本コンテンツを現に保有する者に対し、本コンテンツに係る創作物について、一定の利用を許諾し、又はその他の権利若しくは利益を付与することがあります。
              </span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span>第12条 </span>{" "}
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
              <span>本サイトの変更、停止、中止、終了</span>
            </p>
            <p>
              <span>
                1.
                当社は、当社が必要と判断した場合には、本サイトに新たな機能を追加し、本サイトの内容又は名称を変更し、その他本サイトに一切の変更を加えることができるものとします。
              </span>
            </p>
            <p>
              <span>
                2.
                当社は、次の各号に定めるいずれかの事由が生じた場合、本サイトの全部又は一部の提供を停止し、中止し、又は終了することができるものとします。
              </span>
            </p>
            <p>
              <span>
                　(1)当社が、定期的又は緊急に、本サイト提供のためのコンピュータシステムの保守・点検を行う場合
              </span>
            </p>
            <p>
              <span>
                　(2)地震、津波、台風、雷、大雨、洪水等の自然災害、火災、停電その他の不慮の事故、戦争、争議、動乱、暴動、騒乱、労働争議、伝染病その他の疫病及び感染症並びにそれらに伴う社会閉鎖等の不可抗力により、本サイトの運営が不能となった場合
              </span>
            </p>
            <p>
              <span>
                　(3)本サイトの提供のためのコンピュータシステムの不良及び第三者からの不正アクセス、コンピュータウイルスの感染等により本サイトを提供することができない場合
              </span>
            </p>
            <p>
              <span>
                　(4)法律、法令等に基づく措置により本サイトを提供することができない場合
              </span>
            </p>
            <p>
              <span>
                　(5)当社が、事前に電子メールその他の手段により、合理的な範囲で周知した場合
              </span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
              <span>(6)その他、当社がやむを得ないと判断した場合</span>
            </p>
            <p>
              <span>
                3.
                当社は、前項により本サイトの全部又は一部の提供を停止し、中止し、又は終了する場合には、合理的な範囲で、事前に本サイト又は当社の別途指定するウェブサイトに掲載する等の手段により、ユーザーに対し、その旨を通知するものとします。ただし、緊急の場合には、この限りではありません。
              </span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span>第13条 </span>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
              <span>反社会的勢力等の排除</span>
            </p>
            <p>
              <span>
                1.
                ユーザーは、自身が反社会的勢力等に該当しないことを表明し、かつ将来にわたっても該当しないことを保証するものとします。
              </span>
            </p>
            <p>
              <span>
                2.
                ユーザーは、自ら又は第三者をして次の各号に定める行為を行わないことを確約するものとします。
              </span>
            </p>
            <p>
              <span>　(1)暴力的な要求行為</span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
              <span>(2)法的な責任を超えた不当な要求行為</span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
              <span>(3)脅迫的な言動をし、又は暴力を用いる行為</span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
              <span>
                (4)風説の流布、偽計若しくは威力を用いて当社の信用を毀損し、又は当社の業務を妨害する行為
              </span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
              <span>
                (5)方法及び態様の如何を問わず反社会的勢力等と関与する行為
              </span>
            </p>
            <p>
              <span>
                3．ユーザーは、随時、当社が行う、前項各号に掲げる者が反社会的勢力でないことに関する調査に合理的な範囲で協力し、当社から合理的理由に基づき求められた資料等を提出するものとします。
              </span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span>第14条　免責</span>
            </p>
            <p>
              <span>
                1.
                購入ユーザーは、データのホスト先のサービス等により、本コンテンツを利用できなくなる場合があることに予め同意します。
              </span>
            </p>
            <p>
              <span>
                2.
                当社は、本コンテンツにつき、その機能、性能、有用性、有益性、資産価値、他の商品等との適合性について保証するものではありません。
              </span>
            </p>
            <p>
              <span>
                3.
                当社は、本サイトにより提供される情報につき、その完全性、確実性、妥当性、有用性、最新性、適法性又はユーザーの利用目的及び環境との適合性を保証するものではありません。
              </span>
            </p>
            <p>
              <span>
                4.
                当社は、当社の責めに帰すべき事由がない限り、本サイトの利用又は本コンテンツに関してユーザーに生じた損害等に関して一切の責任を負わないものとします。
              </span>
            </p>
            <p>
              <span>
                5.
                当社は、本サイトの利用又は本コンテンツに関してユーザーに生じた損害等について、本コンテンツの当社からの購入金額を超えて賠償する責任を負わないものとし、また、当社は、付随的損害、間接損害、特別損害、及び逸失利益にかかる損害については賠償する責任を負いません。但し、当社の故意又は重過失によりユーザーに損害が生じた場合はこの限りではありません。
              </span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span>第15条</span>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
              <span>本規約の改訂</span>
            </p>
            <p>
              <span>
                1．当社は、次の各号のいずれかに該当する場合には、本規約の変更の効力発生時期を定め、かつ、本規約を変更する旨、変更後の規約の内容及びその効力発生時期を本サイト若しくは当社の別途指定するウェブサイト等に掲載することによる公表その他適切な方法で周知することにより、本規約を変更することができるものとします。
              </span>
            </p>
            <p>
              <span>　(1)変更の内容が利用者の利益に適合する場合</span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
              <span>
                (2)変更の内容が本規約に係る取引の目的に反せず、変更の必要性、変更後の内容の相当性その他の変更に係る事情等に照らし、合理的なものである場合
              </span>
            </p>
            <p>
              <span>
                2．前項による本規約の変更は、前項の効力発生時期から効力を生じるものとし、ユーザーには変更後の規約が適用されます。
              </span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span>第16条　準拠法</span>
            </p>
            <p>
              <span>
                本規約の成立、効力、履行及び解釈に関しては、日本法が適用されるものとします。
              </span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span>第17条　管轄</span>
            </p>
            <p>
              <span>
                本サイトの利用（本コンテンツを含みます。）に起因又は関連して、当社とユーザー（購入ユーザーを含みます。）との間で紛争が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
              </span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
            <p>
              <span style={{ whiteSpace: "pre" }}>&#xa0;</span>
            </p>
          </div>
          <div className="content-right"></div>
        </div>
      </div>
      <div className="App-footer">
        <AppFooter />
      </div>
    </>
  );
}
