import "./explanation.css";

export default function Explanation() {
  return (
    <div className="explaination" id="explaination">
      <div className="sns-icons">
        <SnsIcons />
      </div>
      <br />
      <div className="note-title">
        <div className="title-content">注意事項</div>
      </div>
      <div className="explaination-bottom">
        <PurchaseNotice />
      </div>
    </div>
  );
}

// import React from 'react';
const SnsIcons = () => {
  return (
    <div className="sns">
      <div className="sns-twitter">
        <a
          href="https://twitter.com/NFTIDOLHOUSE"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/images/twitter-image.svg"
            alt="twitter"
            height="100%"
            className="twitter-image"
          />
        </a>
      </div>
      <div className="sns-discord">
        <a
          href="https://discord.com/invite/fuvYbncega"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/images/discord-image.svg"
            alt="discord"
            height="100%"
            className="discord-image"
          />
        </a>
      </div>
    </div>
  );
};

function PurchaseNotice() {
  return (
    <div>
      <p>
        <span>※必ずお読みください※</span>
      </p>
      <p>
        <span>購入時に下記の注意事項および利用規約への同意が必要です。</span>
      </p>
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>【注意事項】</span>
      </p>
      <p>
        <span>
          ◆このNFT（SBT）は、日本テレビ放送網株式会社（以下「日本テレビ」）が販売する商品です。
        </span>
      </p>
      <p>
        <span>
          ※NFT（Non-Fungible
          Token）とは、「このデータは世界に１つだけのもので、この人が保有しています」という証明が可能なデジタルデータのことです。ブロックチェーンの技術を用いることで、偽造や改ざんができない仕組みになっています。現在の保有者が誰なのかという情報も記録されるため、購入者は「このNFTは私のものです」と証明することが可能です。
        </span>
      </p>
      <p>
        <span>
          ※この商品はNFTという性質上、現物の写真や、デジタルデータ入りのデバイスが自宅に届くといった商品ではございませんので、ご注意ください。
        </span>
      </p>
      <p>
        <span>
          ※またSBTという技術仕様のため、転売や譲渡などはできませんのでご了承ください。
        </span>
      </p>
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>
          ◆このNFT（SBT）を購入した方に、NFT IDOL HOUSEのNFTを送付します。
        </span>
      </p>
      <p>
        <span>
          NFT（SBT）１枚につき、NFT IDOL HOUSEのNFT１枚の送付となります。
        </span>
      </p>
      <p>
        <span>
          送付されるNFT IDOL
          HOUSEのNFTの絵柄はランダムですので、指定できません。
        </span>
      </p>
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>
          ◆満20歳未満の方は保護者などの親権者・その他の法定代理人の同意を得た上でご購入ください。
        </span>
      </p>
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>
          ◆このNFT（SBT）および送付されるNFT IDOL
          HOUSEのNFTの知的財産権（著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権）は、日本テレビに帰属します。購入者ができること、購入者であっても許諾なしにはできないことを以下に示します。
        </span>
      </p>
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>【購入者ができること】</span>
      </p>
      <p>
        <span>
          ・購入したNFTの画像を閲覧すること、SNSのアイコン等に使用すること
        </span>
      </p>
      <p>
        <span>・購入したNFTを二次流通（転売）すること</span>
      </p>
      <p style={{ marginLeft: "4px" }}>
        <p>
          <span>
            ※NFT IDOL HOUSEのNFTのみ。SBTは技術仕様上、転売や譲渡はできません。
          </span>
        </p>
        <p>
          <span>
            ※一部のマーケットプレイス（販売サイト）での二次流通が可能です。
          </span>
        </p>
        <p>
          <span>※購入価格以上で二次流通できるかどうかは保証できません。</span>
        </p>
      </p>
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>【購入者であっても許諾なしにできないこと】</span>
      </p>
      <p>
        <span>
          ・このNFTの画像を私的使用以外の目的で印刷すること、このNFTの画像をそのまま用いて別のNFTを発行することなど
        </span>
      </p>
      <p>
        <span>・このNFTの画像を他の人に配布すること</span>
      </p>
      <p>
        <span>・このNFTの画像を展示すること、書籍等に掲載すること</span>
      </p>
      <p>
        <span>・このNFTの画像を加工・改変することなど</span>
      </p>
      <p>
        <span>・このNFTの画像をそのまま別の作品内で二次利用することなど</span>
      </p>
      <p>
        <span>
          ・このNFTの画像を販売すること、グッズ化すること、他の商品の宣伝目的や政治・宗教活動等で利用することなど
        </span>
      </p>
      <p>
        <span>・その他、知的財産権等の権利を侵害するおそれのある行為</span>
      </p>
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>◆NFT IDOL HOUSEのNFTは一部二次創作を許諾しています。</span>
      </p>
      <p>
        <span>
          詳細は、NFT IDOL
          HOUSE公式Discord内のファンアートガイドラインをご確認ください。
        </span>
      </p>
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>
          ◆このNFTの購入後の保有・転売等につき、取引当事者その他第三者が損害を被った場合、その損害がいかなる原因で発生したものであっても、故意または過失がない限り日本テレビは何らの法的責任も負わないものとします。
        </span>
      </p>
      <p style={{ paddingRight: "4pt", paddingLeft: "4pt" }}>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>◆購入後のお客様都合によるキャンセルはできません。</span>
      </p>
      <p style={{ paddingRight: "4pt", paddingLeft: "4pt" }}>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>
          ◆データのホスト先のサービス終了等により、画像データ等が閲覧できなくなる場合があります。
        </span>
      </p>
      <p>
        <span>
          故意または過失がない限り日本テレビは何らの法的責任も負わないものとします。
        </span>
      </p>
      <p style={{ paddingRight: "4pt", paddingLeft: "4pt" }}>
        <span style={{ whiteSpace: "pre-wrap" }}>&#xa0;</span>
      </p>
      <p>
        <span>
          ◆その他、利用規約および特定商取引法に基づく表記をよく読んだ上でご利用ください。
        </span>
      </p>
      <p>
        <span>利用規約</span>
        <br />
        <span>https://mint.nft-idol-house.com/terms-of-service</span>
      </p>
      <p>
        <span>特定商取引法に基づく表記</span>
        <br />
        <span>
          https://mint.nft-idol-house.com/specified-commercial-transaction
        </span>
      </p>
    </div>
  );
}
